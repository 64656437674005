import React, { Fragment, useState, useEffect } from 'react';
import { Field, reduxForm, change as changeForm } from 'redux-form';
import {
    renderFieldCheck,
    SelectField2,
    renderTextArea,
    AsyncSelectField2
} from '../../../../Utils/renderField/renderField';
import { LABEL_STATUS_ORDER_BODY_SHOP, SWALMOD } from '../../../../../../utility/constants'
import { ButtonEnderezadoPinturaGuardar, ButtonSalirGuardar } from '../../../../Utils/Button'
import { NotificationManager } from 'react-notifications'
const warningSvg = require('./../../../../../../../assets/img/icons/warning.svg')

import { api } from "api";

const getOperador = (search) => {
    return api.get("garage/my_users", { search, 'type': 'body_shop' }).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

const required = value => (value ? undefined : 'Campo requerido.');

const ElementoCheck = ({ name = "", title = "", borderGris, dispatch, id = 0, status = 10 }) => {
    const [selectActive, setSelectActive] = useState(false)
    const f_selectActive = (e) => {
        if (!e) {
            dispatch(changeForm('WorkOrdersForm', 'order_body_shop.' + name + "_user", null))
            dispatch(changeForm('WorkOrdersForm', "order_body_shop.check_all", null))
        }
        setSelectActive(e)
    }
    return (
        <Fragment>
            <div className={`border-table-verde-md d-flex py-1 px-0 px-sm-1 flex-md-row flex-column align-items-center ${borderGris ? "border-tablla-gris-md" : ''}`}>
                <div className="flex-1 order-2 px-1 d-inline d-md-flex w-100">
                    <div className={`w-100 ${selectActive ? "select-border-verde" : ""}`}>
                        <Field
                            name={`order_body_shop.${name}_user`}
                            component={AsyncSelectField2}
                            loadOptions={getOperador}
                            placeholder={selectActive ? "Mecánico/Operador" : "Desactivado."}
                            validate={selectActive ? [required] : []}
                            disabled={!selectActive}
                            isDisabled={false}
                        />
                    </div>
                    <span className="bold d-block d-md-none d-none mx-3 mt-0 mb-1" style={{ paddingBottom: 3 }} />
                </div>
                <div className="position-relative flex-2 px-1 pb-2 pb-md-0 d-flex w-100">
                    <label className="mb-0 d-flex flex-1 py-1 px-0 px-sm-1 flex-row algin-items-center">
                        <div className="flex-1 px-1 d-flex justify-content-center centrar-check">
                            <Field
                                name={`order_body_shop.${name}`}
                                component={renderFieldCheck}
                                onCambio={f_selectActive}
                            />
                        </div>
                        <div className="flex-2 px-1 m-auto">
                            <div className="gris-oscuro my-auto">{title}</div>
                        </div>
                    </label>
                    <div className={`position-absolute w-100 h-100 ${id <= status ? "" : "d-none"} `} style={{ cursor: "not-allowed" }} />
                </div>
            </div>
        </Fragment>
    )
}


const renderFieldError = ({ meta: { submitFailed, error } }) => (
    <React.Fragment>
        {submitFailed && <div className="invalid-feedback d-inline">{error}</div>}
    </React.Fragment>
)

const renderSelectStatus = ({ input, meta: { dispatch }, setSelectStatus }) => {
    useEffect(() => {
        if (input.value) {
            let newStatusSelec = []
            for (let index = 3; index <= 10; index++) {
                const item = LABEL_STATUS_ORDER_BODY_SHOP[index]
                if (input.value[item.search] === true) {
                    newStatusSelec.push({ name: item.label, id: index })
                } else {
                    if (input.value.select_status && input.value.select_status.id == index) {
                        const cod_status = input.value.status
                        dispatch(changeForm('WorkOrdersForm', 'order_body_shop.select_status', { name: LABEL_STATUS_ORDER_BODY_SHOP[cod_status].label, id: cod_status }))
                    }
                }
            }
            setSelectStatus(newStatusSelec)
        }
    }, [input.value])
    return ""
}

const LIST_CHECK = [
    { id: 1, title: "Diagnóstico", name: 'diagnostic'},
    { id: 2, title: "Cotización", name: 'quotation'},
    { id: 3, title: "Desarmado", name: "disarmed" },
    { id: 4, title: "Enderezado", name: "straightened" },
    { id: 5, title: "Alistado", name: "enlisted" },
    { id: 6, title: "Pintura", name: "painting" },
    { id: 7, title: "Pulida", name: "polished" },
    { id: 8, title: "Armado", name: "armed" },
    { id: 9, title: "Control de calidad", name: "quality_control" },
    { id: 10, title: "Lavado del vehículo", name: "car_wash" }
]

let WorkOrdersForm = (props) => {
    const { handleSubmit, previousStep, dispatch, guardar, editar, item } = props;
    const [status, setStatus] = useState(10)
    const [selectStatus, setSelectStatus] = useState([])
    const [onChangeStatus, setOnChangeStatus] = useState(false)
    useEffect(() => {
        if (editar) {
            if (item) {
                if (item.order_body_shop) {
                    const cod_status = item.order_body_shop.status
                    setStatus(cod_status)
                    dispatch(changeForm('WorkOrdersForm', 'order_body_shop.select_status', { name: LABEL_STATUS_ORDER_BODY_SHOP[cod_status].label, id: cod_status }))
                }
            }
        } else {
            setStatus(0)
        }
    }, [item, onChangeStatus])

    const f_selectActive = (e) => {
        if (e === true || e === false) {
            for (let index = 0; index < LIST_CHECK.length; index++) {
                const name = LIST_CHECK[index].name;
                dispatch(changeForm('WorkOrdersForm', 'order_body_shop.' + name, e))
            }
        }
    }

    const backToDiagnosis = (id) => {
        SWALMOD.fire({
            title: '¿Regresar a Diagnostico?',
            text: 'Se borrarán los datos de la orden de trabajo.',
            showCancelButton: true,
            confirmButtonText: '¡Sí, regresar!',
            cancelButtonText: 'No',
            reverseButtons: true,
            imageUrl: warningSvg,
            imageAlt: 'Alerta',
            imageWidth: 90,
        }).then(result => {
            if (result.value) {
                api.put(`quotation/${id}/reject_quotation`)
                .then(() => {
                    NotificationManager.success('Se regreso correctamente', 'Éxito', 3000)

                })
                .catch((error) => {
                    let mensaje = 'Error al regresar'
                    if (error) {
                        if (error.detail) mensaje = error.detail;
                    }
                    NotificationManager.error(mensaje, 'ERROR', 3000);
                })
            }
            else{
                setOnChangeStatus(onChangeStatus => !onChangeStatus)
            }
        })
    }

    const toDelayTheStates = (id, state_order,nameState)=> {
        SWALMOD.fire({
            title: `¿Regresar a ${nameState}?`,
            text: `Si regresas deberas finalizar las tareas de nuevo de cada estado siguiente a: ${nameState}`,
            showCancelButton: true,
            confirmButtonText: '¡Sí, regresar!',
            cancelButtonText: 'No',
            reverseButtons: true,
            imageUrl: warningSvg,
            imageAlt: 'Alerta',
            imageWidth: 90,
        }).then(result => {
            if (result.value) {
                api.put(`work_orders/${id}/to_delay_the_states`, { state_order: state_order })
                .then(() => {
                    NotificationManager.success('Se regreso correctamente', 'Éxito', 3000)

                })
                .catch((error) => {
                    let mensaje = 'Error al regresar'
                    if (error) {
                        if (error.detail) mensaje = error.detail;
                    }
                    NotificationManager.error(mensaje, 'ERROR', 3000);
                })
            }
            else{
                setOnChangeStatus(onChangeStatus => !onChangeStatus)
            }
        })
    }

    
    const getValue = (e) => {
        if (e) {
            const state_order = e.id
            if( state_order < status) {
                const nameState = e.label
            if (state_order === 1) {
                backToDiagnosis(item.order_body_shop.diagnostic_task.work_order)
            }
            else{
            toDelayTheStates(
                item.id,
                state_order,
                nameState,
                )
            }
            }
        }
    }

    return (
        <form onSubmit={handleSubmit} >
            <Field name="order_body_shop" component={renderSelectStatus} setSelectStatus={setSelectStatus} />
            <div className="row card-form pt-4">
                <div className="col-12 padding-custom mb-0 mb-md-4 mt-4 ">
                    <div className="my-0 d-block">
                        <div className="react-bs-container-header" style={{ height: 25 }} >
                            <div className="d-md-flex row py-0 px-0 px-sm-1 px-lg-1 px-xl-1">
                                <div className="flex-1 ml-2 pl-md-0 pl-4 pl-md-0 d-flex justify-content-center align-items-center checkbox-white">
                                    {status == 0 &&
                                        <Field
                                            name="order_body_shop.check_all"
                                            component={renderFieldCheck}
                                            onCambio2={f_selectActive}
                                        />
                                    }
                                </div>
                                <div className="flex-3 px-1">
                                    <label htmlFor="name" className="ml-2 blanco d-block my-0 py-0">ELEMENTO</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {LIST_CHECK.map((item, index) => <ElementoCheck key={index} dispatch={dispatch} status={status}  {...item} borderGris={((index % 2) != 0)} />)}
                    <Field name="order_body_shop.oneItemError" component={renderFieldError} />
                </div>
            </div>

            <div className="col-12 padding-custom mb-3">
                <label htmlFor="description">Descripción*</label>
                <Field name="order_body_shop.description" component={renderTextArea} type="text" className="form-control" validate={[required]} />
            </div>
            {status == 0 ?
                <ButtonEnderezadoPinturaGuardar
                    link={previousStep}
                    btnText1="Anterior"
                    link2={guardar}
                />
                :
                <Fragment>
                    {((status > 0) && (status <= 8)) && <div className="row card-form">
                        <div className="col-md-6 padding-custom mb-3">
                            <label htmlFor="order_body_shop.select_status">Estado actual</label>
                            <Field onChange={(e)=>{getValue(e)}}
                                name="order_body_shop.select_status"
                                options={selectStatus}
                                component={SelectField2}
                                placeholder="Estado actual"
                            />
                        </div>
                    </div>
                    }
                    <ButtonSalirGuardar
                        link={previousStep}
                        btnText1="Anterior"
                    />
                </Fragment>
            }

        </form >
    );
};
WorkOrdersForm = reduxForm({
    form: 'WorkOrdersForm',
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate: (data) => {
        let errors = {};
        errors.order_body_shop = {
            oneItemError: "Selecciona por lo menos un flujo para poder enviar."
        }
        if (data && data.order_body_shop) {
            for (const key in LIST_CHECK) {
                const clave = LIST_CHECK[key].name
                if (data.order_body_shop[clave] === true) {
                    delete errors.order_body_shop
                    break;
                }
            }
        }
        return {
            ...errors
        }
    },
})(WorkOrdersForm);

export default WorkOrdersForm;
