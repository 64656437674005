import React, { Fragment, useState, useEffect } from 'react';
import { Field, reduxForm, change as changeForm } from 'redux-form';
import {
    renderTextArea,
    AsyncSelectField2
} from '../../../../Utils/renderField/renderField';

import { ButtonSalirGuardar } from '../../../../Utils/Button'
import { connect } from 'react-redux';
import { api } from "api";


const getOperador = (search) => {
    return api.get("garage/my_users", { search, 'type': 'mechanic' }).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

let WorkOrdersForm = (props) => {
    const { handleSubmit } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="row card-form">
                <div className="col-12 padding-custom mb-0 mb-md-4 mt-4">
                    <span className="text-18 bold d-block" style={{ borderBottom: "2px #CD0A0A solid", paddingBottom: 5 }}>ASIGNAR</span>
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="user">Mecánico/Operador</label>
                    <Field
                        name="user"
                        component={AsyncSelectField2}
                        loadOptions={getOperador}
                        placeholder="Mecánico/Operador"
                    />
                </div>
            </div>
            <ButtonSalirGuardar link="/mechanic_orders" btnText2="Guardar" />
        </form >
    );
};
WorkOrdersForm = reduxForm({
    form: 'ControlCalidadForm',
})(WorkOrdersForm);

export default WorkOrdersForm;
